<template>
  <div id="TableContainer">
    <div>
      <Header table="items"
              @search="search"
              v-bind:hidePanel="hidePanel"
              ref="counter"
              @deleteAll="deleteItems"
              @cloneSelected="cloneItems"
              @showActiveItems="showActiveItems"
              @download="downloadFile"
      />
    </div>

    <vue-confirm-dialog></vue-confirm-dialog>

    <div id="table">
      <ItemList
        v-bind:items="changedItems"
        @setHeaderVisible="setHeaderVisible"
        @setPanelVisible="setPanelVisible"
      />
    </div>
  </div>
</template>


<script>
import axios from "axios";
import ItemList from "./ItemList";
import Header from "../Header";
import Link from "../utils/Link";
import VueConfirmDialog from "vue-confirm-dialog";
import Vue from "vue";
import SideBarMenu from "../SideBarMenu";
import VueToast from "vue-toast-notification";
import ui from "../utils/ui";
import moment from "moment";

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

let tableHeaderPosition = [0];

export default {
  components: {
    ItemList: ItemList,
    Header: Header
  },
  data() {
    return {
      items: [],
      hidePanel: true,
      array: [],
      showOnlyActiveItems: true,
      sbActivity: true,
      lastQuery: (x) => x,
      isRoleAdmin: false
    };
  },

  created: function () {
    window.addEventListener('scroll', this.scrolling);
    window.addEventListener('resize', this.windowResize);
    window.localStorage.removeItem("savedItem");
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrolling);
    window.removeEventListener('resize', this.windowResize);
  },

  computed: {
    changedItems: function () {
      return this.items.filter(x => this.filterByStatus(x) && this.lastQuery(x, this.isInclude));
    }
  },

  mounted() {
    this.isRoleAdmin = SideBarMenu.methods.isRoleAdmin();
    this.$emit("changeComponent", 'items');
    this.getItems().then(() => {
      this.showActiveItems();
      this.alignColumns();
    });
  },

  methods: {
    search(qsearch) {
      this.lastQuery = qsearch;
    },

    convertAndConcatStrings(s1, s2) {
      s1 = s1.toString().concat("x");
      return s1.concat(s2.toString());
    },

    isInclude(item, query) {
      return this.fieldContain(query, item.id.toString())
          || this.fieldContain(query, item.system)
          || this.fieldContain(query, item.manufacturer.name)
          || this.fieldContain(query, item.mountType)
          || this.dimensionContain(query, item.width, item.depth, item.thickness)
          || this.dimensionContain(query, item.horizontalStep, item.verticalStep)
          || this.dateFieldContain(query, item.updated, 'DD.MM.YYYY HH:mm')
          || this.statusContain(query, item.status);
    },

    fieldContain(query, field) {
      if (field === null)
        return false;
      return field.toLowerCase().indexOf(query) !== -1;
    },

    dateFieldContain(query, field, dateFormat) {
      if (field !== null) {
        return moment(field).format(dateFormat).indexOf(query) !== -1;
      }
    },

    statusContain(query, status) {
      if (status === null) {
        return false;
      }
      return (
          status === "ACTIVE" ? this.$t("soloItem.active") : this.$t("soloItem.deleted")
      ).toLowerCase().indexOf(query) !== -1;
    },

    dimensionContain(query, ...dim) {
      let dimensions = dim[0].toString();
      if (dim.length > 1) {
        for (let i = 0; i < dim.length - 1; i++) {
          dimensions = this.convertAndConcatStrings(dimensions, dim[i + 1]);
        }
      }
      return dimensions.indexOf(query) !== -1;
    },

    setPanelVisible(array) {
      this.array = array;
      this.$refs.counter.changeLabel(array.length, "items");
      this.hidePanel = false;
    },

    setHeaderVisible() {
      this.hidePanel = true;
    },

    getItems() {
      const headers = Link.methods.getHeaders();
      return axios.get(
          Link.methods.getItemsAllUrl(),
          {headers}
      ).then(res => {
        this.items = res.data;
        for (let item = 0; item < this.items.length; item++) {
          for (let field in this.items[item]) {
            if (this.items[item][field] === -1) {
              this.items[item][field] = '?'
            }
          }
        }
      }).catch(()=>{
        this.createToast(this.$t("mainItem.itemsNotFound"), "error")
      });
    },

    showActiveItems() {
      const filter = window.localStorage.getItem("activeItemsFilter");
      if (filter != null) {
        this.showOnlyActiveItems = (filter === 'true');
      }
      if (!this.isRoleAdmin) {
        this.showOnlyActiveItems = true;
      }
    },

    filterByStatus(item) {
      return (this.showOnlyActiveItems)
        ? (
          item.status !== "DELETED"
          && item.manufacturer.status !== "DELETED"
        )
        : true;
    },

    deleteItems() {
      this.$confirm(
        {
          message: this.$t("mainItem.confirmMessage"),
          button: {
            no: this.$t("mainItem.confirmNo"),
            yes: this.$t("mainItem.confirmYes")
          },
          callback: confirm => {
            if (confirm) {
              const headers = Link.methods.getHeaders();
              const promises = [];
              for (let i = 0; i < this.array.length; i++) {
                promises.push(axios.delete(Link.methods.getItemDeleteUrl(this.array[i]), {headers}));
              }
              Promise.all(promises).then(() => {
                if (this.array.length === 1) {
                  this.createToast(this.$t("mainItem.deleteItem"), "success")
                } else {
                  this.createToast( this.$t("mainItem.deleteItems", [this.array.length]), "success")
                }

                this.$emit('removeCheckboxes');
                this.array = [];
                this.getItems();
                this.setHeaderVisible();
              }).catch(() => {
                this.createToast(this.$t("mainItem.itemsDeleteErrorToast"), "error")
              })
            }
          }
        }
      );
    },

    cloneItems() {
      let headers = Link.methods.getHeaders();
      new Promise((resolve) => {
        for (let i = 0; i < this.array.length; i++) {
          axios.get(Link.methods.getItemById(
            this.array[i]),
            {headers}
          ).then(res => {
            axios.post(
              Link.methods.getItemsCreateUrl(),
              res.data,
              {headers}
            ).then(() =>
              resolve()
            );
          });
        }
      }).then(() => {
        this.getItems()
        this.$emit('removeCheckboxes');
        this.array = [];
      })
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    },

    windowResize() {
      this.alignColumns();
    },

    downloadFile() {
      const headers = Link.methods.getHeaders();
      axios.get(
        Link.methods.getExportCsvUrl("article"),
        {headers}
      ).then(res => {
        let filename = "article.csv"
        let text = res.data;
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);
      })
    },

    scrolling() {
      this.alignColumns();
      ui.methods.stickHeader(tableHeaderPosition);
    },

    alignColumns() {
      if (this.changedItems.length > 0) {
        let rowWidth = 0;
        rowWidth += ui.methods.copyElementWidthIfDifferent('.idTd', 'th.id');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.systemTd', 'th.system');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.manufacturerTd', 'th.manufacturer');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.mountTypeTd', 'th.mountType');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.wxdxtTd', 'th.wxdxt');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.l1xl2Td', 'th.l1xl2');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.imageThTd', 'th.imageCol');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.dateTd', 'th.date');
        if (this.isRoleAdmin) {
          rowWidth += ui.methods.copyElementWidthIfDifferent('.checkboxTd', 'th.checkbox');
          rowWidth += ui.methods.copyElementWidthIfDifferent('.statusTd', 'th.status');
        }
        ui.methods.copyElementWidthIfDifferent(rowWidth, '#tableHead');
      }
    }
  }
}
</script>


<style scoped lang="scss">
@import "../../../public/styles/tables.scss";

</style>